import React from "react";
import styles from "./Footer.module.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { withNamespaces } from "react-i18next";

const now = new Date();
const year = now.getFullYear();

const Footer = props => (
  <div className={styles.Footer} data-testid="Footer">
    <Navbar
      bg="light"
      expand="lg"
      sticky={props.static}
      style={{
        minHeight: 84,
        border: "solid .75px #E4E4E4",
        backgroundColor: "#FAFAFA",
        fontSize: 13,
        color: "#808080"
      }}
    >
      <Navbar.Brand href="/" style={{ paddingTop: 0 }}>
        <span style={{ fontSize: 13, color: "#0089E1" }}>RightCom © {year}</span>
      </Navbar.Brand>
      <Nav className="mr-auto footerlinks">
        <Nav.Link href="https://right-com.com/product/appointment-scheduling-software/">{props.t("learn_about")}</Nav.Link>
        <Nav.Link href="https://right-com.com" target="_blank">
          {props.t("more_prods")}
        </Nav.Link>
        <Nav.Link href="https://right-com.com/platform/" target="_blank">
          {props.t("go_xp")}
        </Nav.Link>
      </Nav>
      <Nav className="mr-0">
        <Nav.Link href={"http://right-com.com"}>
          {props.t("prod_of")}{" "}
          <Image
            style={{ marginLeft: 5, marginBottom: 3,height:17 }}
            src="/assets/rightcom.svg"
            fluid
          />
        </Nav.Link>
      </Nav>
    </Navbar>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default withNamespaces()(Footer);

import React from "react";
import "./Blacknav.module.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { withNamespaces } from "react-i18next";
import GetStore from "../../getStore.js";
import Cookies from "universal-cookie";
import { NAVIGATION } from "../../constants/constants";

function Blacknav({ t }) {
  const storeData = GetStore();
  const cookies = new Cookies();
  const redirectionUrl = cookies.get("rightTimeAlias")
    ? "https://" + cookies.get("rightTimeAlias") + ".righttime.rightcomtech.com"
    : "https://account.rightcomtech.com";

  return (
    <Navbar
      bg="dark"
      expand="lg"
      variant="dark"
      style={{ minHeight: 64 }}
      sticky={"top"}
    >
      <Navbar.Brand href="/" className={"d-none d-sm-block"}>
        <img
          alt=""
          src="/assets/righttime_inverse.svg"
          width="124"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Nav
        className="ml-auto"
        justify
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Nav className="Item">
          <Nav.Link href={NAVIGATION.FEATURES}>{t("features")}</Nav.Link>
        </Nav>
        <Nav className="Item">
          {!storeData.userIsConnected ? (
            <Nav.Link href={redirectionUrl}>{t("sign_in")}</Nav.Link>
          ) : (
            " "
          )}
        </Nav>
        <Nav className="Item" style={{ paddingLeft: 15 }}>
          <Button size={"sm"} href={redirectionUrl} variant="outline-light">
            {t("get_started")}
          </Button>
        </Nav>
      </Nav>
    </Navbar>
  );
}

Blacknav.propTypes = {};

Blacknav.defaultProps = {};

export default withNamespaces()(Blacknav);
